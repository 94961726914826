import React, { Fragment } from "react";
import { Link } from "gatsby";
import CTA from "./CTA";
import CTALink from "./CTALink";

export default ({ hideCta, settings, footer }) => {
  const { _rawContent, _rawLinks } = footer;
  const { title } = settings;

  return (
    <Fragment>
      {!hideCta && (
        <div className="site-footer-cta">
          <div className="blue-wave-top">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1683 163">
              <path
                fill="#038AFF"
                fillRule="evenodd"
                d="M1682.750056.03670112C1374.413865-3.2037475 1053.580992 209.544841 463.549936 79.9426437 249.464186 32.9180281 109.533926 46.3247694-.000004 96.5825428v66.3601012h1682.75006"
              />
            </svg>
          </div>
          <div className="blue-wave-middle">
            <div className="text-center">
              <h2 className="xl text-white">{_rawContent.heading.text}</h2>
              <p>
                <CTA {..._rawContent.cta} size="xl" color="white" />
              </p>
              <p>{_rawContent.subheading}</p>
            </div>
          </div>
          <div className="blue-wave-bottom">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1683 163">
              <path
                fill="#038AFF"
                fillRule="evenodd"
                d="M0 162.90594288C308.336191 166.1463915 629.169064-46.602197 1219.20012 83.0000003c214.08575 47.0246156 354.01601 33.6178743 463.54994-16.6398991V0H0"
              />
            </svg>
          </div>
        </div>
      )}
      <footer className="site-footer">
        <div className="row">
          <div className="col-xs-12 col-sm-12">
            <ul className="primary-links">
              {_rawLinks.primaryLinks.items.map((i) => (
                <li key={i._key}>
                  <CTALink {...i} />
                </li>
              ))}
            </ul>
          </div>
          <div className="col-xs-12 col-md-4">
            {!hideCta && (
              <Link to="/pricing" className="button button-blue">
                Free 14 Day Trial
              </Link>
            )}
            <p className="text-grey">Follow Us</p>
            <div className="footer-social-icons">
              <a href={settings.facebook}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264 512">
                  <path
                    fill="#038AFF"
                    d="M77 512V283H0v-91h77v-72C77 42 124 0 194 0c33 0 62 3 70 4v81h-48c-38 0-45 18-45 44v63h85l-12 91h-73v229"
                  />
                </svg>
              </a>
              <a href={settings.twitter}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="#038AFF"
                    d="M459 152l1 13c0 139-106 299-299 299-59 0-115-17-161-47a217 217 0 0 0 156-44c-47-1-85-31-98-72l19 1c10 0 19-1 28-3-48-10-84-52-84-103v-2c14 8 30 13 47 14A105 105 0 0 1 36 67c51 64 129 106 216 110-2-8-2-16-2-24a105 105 0 0 1 181-72c24-4 47-13 67-25-8 24-25 45-46 58 21-3 41-8 60-17-14 21-32 40-53 55z"
                  />
                </svg>
              </a>
              <a href={settings.instagram}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path
                    fill="#038AFF"
                    d="M224 141a115 115 0 1 0 0 229 115 115 0 0 0 0-229zm0 190a75 75 0 1 1 0-150 75 75 0 0 1 0 150zm147-195a27 27 0 1 1-54 0 27 27 0 0 1 54 0zm76 28c-2-36-10-68-37-94-26-27-58-35-93-37-37-2-148-2-185 0-36 2-68 10-94 36S3 129 1 164c-2 37-2 148 0 185 2 36 10 68 37 94s58 35 94 36c37 3 148 3 185 0 35-1 67-9 93-36 27-26 35-58 37-94 2-37 2-147 0-184zm-48 224c-8 20-23 35-43 43-29 11-99 9-132 9s-103 2-132-9c-20-8-35-23-43-43-11-29-9-99-9-132s-2-103 9-132c8-20 23-35 43-43 30-11 100-9 132-9s103-2 132 9c20 8 35 23 43 43 12 29 9 99 9 132s3 103-9 132z"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginTop: "4rem" }}>
          <div className="col-sm-9">
            <ul className="ul-inline">
              {_rawLinks.secondaryLinks.items.map((i) => (
                <li key={i._key}>
                  <CTALink {...i} />
                </li>
              ))}
            </ul>
          </div>
          <div className="col-sm-8 text-right text-grey">
            © 2020 - {title}
          </div>
        </div>
      </footer>
    </Fragment>
  );
};
