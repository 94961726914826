import React from "react";
import { Link } from "gatsby";
import cx from "classnames";

const showIntercom = (e) => {
  e.preventDefault();
  const Intercom = window.Intercom;
  if (Intercom) {
    Intercom("showNewMessage");
  } else {
    alert("Intercom not loaded.");
  }
};

const CTA = ({
  kind,
  landingPageRoute,
  route,
  title,
  size,
  color = "blue",
  className,
  noArrow,
  ...other
}) => {
  const intercom = route === "#intercom";
  let link = route || "#";
  if (
    landingPageRoute &&
    landingPageRoute.slug &&
    landingPageRoute.slug.current
  ) {
    link = "/" + landingPageRoute.slug.current;
  } else if (other.link) {
    link = other.link;
  }

  const arrow = !noArrow && (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 11" width="1rem">
      <path
        fill="#038AFF"
        fillRule="evenodd"
        d="M11.5857864 4.5L8.79289322 1.70710678c-.39052429-.39052429-.39052429-1.02368927 0-1.41421356.39052429-.3905243 1.02368927-.3905243 1.41421358 0l4.5 4.5c.3905243.39052429.3905243 1.02368927 0 1.41421356l-4.5 4.50000002c-.39052431.3905243-1.02368929.3905243-1.41421358 0-.39052429-.3905243-.39052429-1.02368929 0-1.41421358L11.5857864 6.5H1c-.55228475 0-1-.44771525-1-1s.44771525-1 1-1h10.5857864z"
      />
    </svg>
  );

  if (kind === "button") {
    const style =
      color && color.hex
        ? { backgroundColor: color.hex, color: "white", boxShadow: '0 2px 12px rgba(0, 0, 0, 0.25)' }
        : undefined;
    if (intercom) {
      return (
        <a
          href="#"
          onClick={showIntercom}
          className="button button-blue"
          style={style}
        >
          {title}
        </a>
      );
    }
    return (
      <Link
        to={link}
        className={cx("button", `button-${color}`, `button-${size}`)}
        style={style}
      >
        {title}
      </Link>
    );
  }

  if (intercom) {
    return (
      <a href="#" className={className} onClick={showIntercom}>
        {title} {arrow}
      </a>
    );
  }

  return (
    <Link to={link} className={className}>
      {title} {arrow}
    </Link>
  );
};

export default CTA;
